.container {
    width: 900px;
    margin: 0 auto;
    padding-block: 50px;
}

.h1 {
    font-size: 5rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1em;
}

.canvas-wrapper {
    position: relative;
    width: fit-content;
    margin: 0 auto;
}

.canvas-wrapper canvas {
    position: relative;
    outline: 0.5em solid black;
    z-index: 1;
    margin-bottom: 0.5em;
}

.popup {
    position: absolute;
    top: 0;
    right: 0;
    width: 236px;
    outline: 0.5em solid black;
    background-color: white;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup--active {
    transform: translateX(100%);
}

.popup h3 {
    margin: 0;
    padding: 0;
    color: black;
    font-size: 24px;
}

button,
.popup input[type="submit"] {
    margin-top: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-clip: padding-box;
    background-color: #282c34;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: 0 2px 4px 0 black;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    padding-inline: 20px;
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    width: auto;
}

button:hover, 
button:focus,
.popup input[type="submit"]:hover,
.popup input[type="submit"]:focus {
    background-color: #4d5566;
}

button:hover,
.popup input[type="submit"]:hover {
    transform: translateY(-1px);
}

button:active,
.popup input[type="submit"]:active {
    background-color: #4d5566;
    transform: translateY(0);
}

.popup input[type="color"] {
    margin-top: 10px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 236px;
}

.popup input[type="color"]:focus{
    border-radius: 0;
    outline: none;
}

.game-controls {
    margin: 0 auto;
    display: flex;
    column-gap: 50px;
    justify-content: center;
}

.game-controls button {
    min-width: 150px;
}

/* Clear shadow dom styles */
::-webkit-color-swatch-wrapper {
    padding: 0;
}

::-webkit-color-swatch{
    border: 0;
    border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner{
    border: 0;
}

::-moz-focus-inner{
    padding: 0;
}
